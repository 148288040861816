import React from 'react'
import Footer from '../Components/Footer'
import Section3 from '../Components/Sections/Section3'
import Section4 from '../Components/Sections/Section4'
import Header from '../Components/Header'
function Blogger() {
  return (
    <div>
        <Header />

                {/* hero-section */}
                <div className="min-h-screen hero-image bg-right-bottom bg-cover flex" style={{'backgroundImage':'url("./slider.png")'}}>
<section className="text-gray-600 body-font">

  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-26 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="title-font sm:text-5xl  mb-4  text-gray-900 font-bold sm:mt-5">Your Go-to Resource to Grow as a


        <br className="hidden lg:inline-block text-bold font-bold" />Content Creator with Yieldlink.Net
      </h1>
   
      <div className="flex justify-center">
        <a 
         rel="noreferrer"
         href="https://publisher.yieldlink.net/register" 
         target="_blank" 
        className=" grid-cols-2 ml-3 bg-white hover:bg-white text-center text-black mx-auto leading-relaxed text-base font-bold py-2 px-4 rounded-full align-center">
 Join as Creator
</a>
      </div>
    </div>
    <div className="lg:max-w-lg lg:w-full">
      <img className="rounded-3xl" alt="hero" src="./blogger-bg-original.jpg" />
    </div>
  </div>
</section>
</div>


        <Section3 />
        <Section4 />
        <Footer />
    </div>
  )
}

export default Blogger