import React from 'react'
import DarkHeader from '../Components/DarkHeader'
import Footer from '../Components/Footer'
import Section4 from '../Components/Sections/Section4'

function Imprint() {
  return (
    <div>
        <DarkHeader />
        <section className="text-gray-600 body-font mt-5 p-12">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col ">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
	  
      <h4 className="title-font sm:text-6xl text-2xl mb-4  text-gray-900 font-bold leading-tight">Imprint
      </h4>
      <p className="mb-8 leading-relaxed text-lg">Yieldlink.net LLC <br />
1942 Broadway St., Ste 314C Boulder, CO 80302<br />
Company EIN 45-2744203<br />
 </p>
 
 <br />
 <h4 className="title-font sm:text-6xl text-2xl mb-4  text-gray-900 font-bold leading-tight">Company Contact
      </h4>
 <p className="mb-8 leading-relaxed text-lg">Yieldlink.net LLC <br />
1942 Broadway St., Ste 314C Boulder, CO 80302<br />
Company EIN 45-2744203<br />
 </p>
    </div>
    <div className="lg:max-w-2xl lg:w-full md:w-1/2 w-5/6">
	 <div className='mt-3'> 
	   <div className="w-12/12 ">
	  <img className="w-12/12" alt="hero" src="Main-slider_img.png" />
	  </div>
	  
     </div>
    </div>
	
  </div>
</section>
        <Section4 />
        <Footer />
    </div>
  )
}

export default Imprint