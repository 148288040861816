import React from 'react'

function Section4() {
  return (
    <div>
        
<section className="text-black body-font ">
  
  <div className="w-11/12 m-auto flex md:flex-row flex-col items-center sm:w-12/12">
     
	 <div className=" sm:w-12/12">
	 
	 {/* <!--inner start---> */}
		<div className="w-full m-auto flex md:flex-row  flex-col items-center place-content-center">
		 <div className=" lg:w-5/12 sm:w-12/12">
		       <img className="w-full p-3 rounded-tl-sm" src="./img/grl2.jpg" alt="blog"  />
			    <div className="sm:w-11/12 ">
				<div className="m-auto bg-orange-400 rounded-bl-3xl  p-2" >
		       <h1 className="title-font h-72 text-center  font-bold mb-3 text-white text-7xl">Follow Us On Youtube</h1>
			   </div>
		</div>
    {/* <!--inner sec end--> */}
		</div>
    {/* <!--inner main1 end--> */}
		 <div className="lg:w-7/12 sm:12/12">
		   <img className="w-full p-0" src="./img/ban.jpg" alt="blog" />
		</div>
    {/* <!--inner main2 end--> */}
	   </div>
     {/* <!--inner end-->   */}
	 {/* <!--inner start--->  */}
	   
    </div>
    {/* <!--outer main1 end--> */}
	 <div className="lg:w-full w-6/12 sm:12/12">
      <div className="w-full sm:w-12/12">
{/* <!--top -->	       */}
 <div className="container mx-auto flex md:flex-row flex-col mt-1 items-center ">
    <div className="sm:w-12/12 p-3">
      <img className="sm:w-12/12 object-cover object-center rounded" alt="hero" src="./img/green.jpg" />
    </div>
    <div className="lg:flex-grow flex flex-col md:items-start md:text-left items-center text-center">
     <div className="w-10/12 bg-green-300 rounded-tr-3xl">
		       <h1 className="title-font  pt-4 pl-3 pr-3 text-center font-bold text-white  text-7xl h-72 rounded-tr-sm" >Follow More On Instagram</h1>
		</div>
    </div>

  </div> 
{/* <!--top -->	 */}
	<div className="w-11/12 bg-white">
		<img className="object-cover object-center rounded pt-0 pl-3 rounded-br-md" alt="hero" src="./img/green4.jpg"  />
	</div>  		  
		  
		  
		  
		  
	  </div>
    {/* <!--col--end--> */}
    </div>
    {/* <!--outer main2 end--> */}
   
 
  </div>
  
</section>
    </div>
  )
}

export default Section4