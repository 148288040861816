
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Imprint from './Pages/Imprint';
import Influencer from './Pages/Influencer';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import Blogger from './Pages/Blogger';
import Cookies from './Pages/Cookies';
import Publisher from './Pages/Publisher';
function App() {
  return (
        <BrowserRouter>
              <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/imprint' element={<Imprint />} />
                    <Route path='/influencer' element={<Influencer />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/blogger' element={<Blogger />} />
                    <Route path='/publisher' element={<Publisher />} />
                    <Route path='/cookies' element={<Cookies />} />
                    <Route path='/terms' element={<Terms />} />
              </Routes>
        </BrowserRouter>
  );
}

export default App;
