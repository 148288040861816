import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Section3 from '../Components/Sections/Section3';
import Section4 from '../Components/Sections/Section4';
import { Link } from 'react-router-dom';
export default function Home() {
    return (
        <>
            <Header />
            {/* hero-section */}
            <div className="min-h-screen hero-image bg-right-bottom bg-cover flex" style={{'backgroundImage':'url("./slider.png")'}}>
<section className="text-gray-600 body-font">

  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-26 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="title-font sm:text-5xl  mb-4  text-gray-900 font-bold sm:mt-5">Open A New Revenue 
        <br className="hidden lg:inline-block text-bold font-bold" />Stream From Your Content
      </h1>
      <p className="mb-8 leading-relaxed text-black">Connect and collaborate with tens of thousands of advertisers in real-time, opening up new opportunities and expanding your reach with our comprehensive and user-friendly platform connect and earn from the world's. </p>
      <div className="flex justify-center">
        <a 
         rel="noreferrer"
         href="https://publisher.yieldlink.net/register" 
         target="_blank" 
        className=" grid-cols-2 ml-3 bg-white hover:bg-white text-center text-black mx-auto leading-relaxed text-base font-bold py-2 px-4 rounded-full align-center">
 Join as Creator
</a>
      </div>
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      <img className="object-cover object-center rounded" alt="hero" src="./Main-slider_img.png" />
    </div>
  </div>
</section>
</div>

 {/* <!--cont end--->
 <!--hero end--->
  */}
 <section className="text-gray-600 mx-auto body-font pt-5 pb-5 mb-5">
 <div className="w-full bg-white pt-5">
  <div className="container w-4/5 px-3 py-14 m-auto bg-gradient-to-b from-gray-200 mt-5 rounded-t-2xl">
    <div className="flex flex-col text-center w-full mb-5 mt-5">
      <h1 className="sm:w-2/3 font-medium title-font mt-5 pt-5 mb-4 text-gray-900 mx-auto leading-relaxed text-base text-center font-bolds sm:text-4xl ">Trusted by 25,000+ world-class brands and organizations of all sizes</h1>
      
    </div>
    <div className="flex flex-wrap p-4 text-center">
      
	  <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-2 py-6 rounded-lg ">
          <img className="object-cover object-center rounded" alt="hero" src="./img/mm1.png" />
        </div>
      </div>
	  
      <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-2 py-6 rounded-lg">
           <img className="object-cover object-center rounded" alt="hero" src="./img/mm2.png" />
        </div>
      </div>
	  
	   <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-2 py-6 rounded-lg">
         <img className="object-cover object-center rounded" alt="hero" src="./img/mm3.png" />
        </div>
      </div>
	  
      <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className="0 px-2 py-6 rounded-lg">
           <img className="object-cover object-center rounded" alt="hero" src="./img/mm4.png" />
        </div>
      </div>
	  
	
	   <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-2 py-6 rounded-lg">
           <img className="object-cover object-center rounded" alt="hero" src="./img/mm5.png" />
        </div>
      </div>
	  
	   <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-4 py-6 rounded-lg">
           <img className="object-cover object-center rounded" alt="hero" src="./img/mm6.png" />
        </div>
      </div>
	  
	   <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-2 py-6 rounded-lg">
           <img className="object-cover object-center rounded" alt="hero" src="./img/mm7.png" />
        </div>
      </div>
	  
	  <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
        <div className=" px-2 py-6 rounded-lg">
           <img className="object-cover object-center rounded" alt="hero" src="./img/mm9.png" />
        </div>
      </div>
	  
  
	   </div>
    </div>
  </div>
</section>

<div className="min-h-screen hero-image bg-cover flex mt-5 pt-5 img-fluid" style={{'backgroundImage':'url(./img/Main-server.jpg)'}}>

<div className="container lg:w-7/12 sm:12/12 ">
   <div className="container w-10/12 px-5 py-24 ml-2">
  
    <div className="flex flex-wrap -m-2 text-center">
      <div className="p-4 md:w-1/2 sm:w-1/2  w-full">
        <div className=" px-4 py-6 rounded-lg bg-teal-100">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M8 17l4 4 4-4m-4-5v9"></path>
            <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
          </svg>
          <h4 className="title-font font-bold text-2xl text-gray-900">Create Account</h4>
          <p className="leading-relaxed">Here you can create your free account, register as a publisher and start your affiliate journey today.</p>
        </div>
      </div>
      <div className="p-4 md:w-1/2 sm:w-1/2 w-full">
        <div className="px-4 py-6 rounded-lg bg-red-100">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
          </svg>
          <h4 className="title-font font-bold text-2xl text-gray-900">Complete Profile</h4>
          <p className="leading-relaxed">Just fill out the required details and sign up the account. Login your account and start exploring.</p>
        </div>
      </div>
      <div className="p-4 md:w-1/2 sm:w-1/2 w-full ">
        <div className="p-3 px-4 py-6 rounded-lg bg-red-100">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M3 18v-6a9 9 0 0118 0v6"></path>
            <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
          </svg>
          <h2 className="title-font font-bold text-2xl text-gray-900">Find Brand</h2>
          <p className="leading-relaxed">You can easily find the brands of your choice and start the promotion and get your rewards.</p>
        </div>
      </div>
      <div className="p-4 md:w-1/2 sm:w-1/2 w-full ">
        <div className=" px-4 py-6 rounded-lg bg-teal-100">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
          <h2 className="title-font font-bold text-2xl text-gray-900">Collaborate</h2>
          <p className="leading-relaxed">In case of any query feel free to reach out the support team with the solution.</p>
        </div>
      </div>
    </div>
  </div>


</div>
</div>
{/* <!--section endd--> */}


<section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="flex flex-wrap w-full">

<div className="container lg:w-7/12 sm:12/12">
      <img className="lg:w-6/12 sm:12/12 object-cover object-center rounded-lg md:mt-0 mt-12 sm:h-1/2 absolute" src="./img/dot.png" alt="step" />
	{/* <!--<img className="w-6/12 object-cover object-center rounded-lg md:mt-0 mt-12 " src="./img/blob2.png" alt="step" style="position: absolute;">--> */}
	
	<img className="lg:w-6/12 sm:12/12 object-cover object-center border md:mt-0 lg:absolute mt-2 rounded-lg overflow-hidden" src="./img/cam.jpg" alt="step"  />
	
	
	
	<img className="w-1/4 object-cover object-center rounded-lg absolute mt-10 md:mt-0 lg:visible sm:invisible md:invisible mt-50" src="./img/img_4.png" alt="step" />
	
	</div>  
	        <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6 ">
           <p className="leading-relaxed text-blue-400 font-medium">Why Choose Us</p>
		    <h1 className="title-fon text-3xl text-gray-900 font-bold pb-4">We offers innovative tools and exclusive campaigns for bloggers and publishers</h1>
		
		<div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
		  
	
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
          </div>
       
    
	
	   
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Cashback/Loyalty</h2>
            <p className="leading-relaxed">Websites that offer instant reward back to the customers, either cash or reward points.</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Social Influencer/KOL</h2>
            <p className="leading-relaxed">An individual or a community posting haul videos or reviews on social networks such as YouTube, Instagram, Tik Tok or RED.</p>
          </div>
        </div>
        <div className="flex relative">
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
            </svg>
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Content/Blogs​</h2>
            <p className="leading-relaxed">Websites that publish promotional or review articles to inform and engage with its audience.</p>
          </div>
        </div>
      </div>
	  
	  
    </div>
  </div>
</section>
{/* <!--section end---> */}



<section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
	   <p className="leading-relaxed text-blue-400 font-bold text-xl">Why Choose Us</p>
      <h1 className="title-font sm:text-3xl text-xl mb-4  text-gray-900 font-bold leading-tight">How We Make The Best Product Reviews
      </h1>
      <p className="mb-8 leading-relaxed">We offers innovative tools and exclusive campaigns for bloggers and publishers seeking affiliate marketing programs to monetize blog content. YieldLink.net is a full service affiliate management platform for high-quality publishers to instantly find, connect and earn from the world's. The Most coveted brands Is leading Affiliate Network where you will find both the amazing Advertisers and high ranking content creating Affiliates.</p>

<div className="flex justify-between mb-1">
  <span className="font-medium text-dark-900  text-2xl">Influencer​​</span>
  <span className="text-sm font-medium  text-white"></span>
</div>
<div className="w-96 bg-gray-200 rounded-full h-4 dark:bg-gray-700">
  <div className="bg-yellow-500 h-4 rounded-full w-64">.</div>
</div>
{/* <!--progess bar-end--> */}
      
	  
	  <div className="flex justify-between mb-1">
  <span className=" font-medium text-dark-900  text-2xl">Blogger​​</span>
  <span className="text-sm font-medium  text-white"></span>
</div>
<div className="w-96 bg-gray-200 rounded-full h-4 dark:bg-gray-700">
  <div className="bg-yellow-500 h-4 rounded-full w-72">.</div>
</div>
{/* <!--progess bar-end--> */}
      

<div className="flex justify-between mb-1">
  <span className=" font-medium text-dark-700  text-2xl">Content</span>
  <span className="text-sm font-medium  text-white"></span>
</div>
<div className="w-96 bg-gray-200 rounded-full h-4 dark:bg-gray-700">
  <div className="bg-yellow-500 h-4 rounded-full w-60">.</div>
</div>
{/* <!--progess bar-end--> */}
      
	  <a 
     rel="noreferrer"
     href="https://publisher.yieldlink.net/register" 
     target="_blank" 
    className="grid-cols-3 p-4 bg-teal-300 hover:bg-teal-300 mt-5 text-white font-bold text-center leading-relaxed text-base rounded-full">
Join as Creator
</a>

    </div>
    <div className="lg:max-w-2xl w-full">
      <img className="object-cover object-center rounded-3xl lg:-ml-12" alt="hero" src="./img/girl.jpg" />
	  <div className="w-3/5  bg-white lg:-mt-72 lg:-ml-24" >
	      <img className="object-cover  h-96 rounded-2xl object-center border border-slate-200 " alt="hero" src="./img/small.jpg" />
	  </div>
    </div>
	
  </div>
</section>
{/* <!--section--->

<!--section start--> */}

<section className="text-gray-600 body-font w-full bg-gradient-to-l from-gray-200 mb-5 p-16">
  <div className="container mx-auto flex  md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24  md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <p className="title-font  mb-4 font-bold text-gray-900 lg:text-5xl md:text-3xl md:pt-20">Maximize Your Affiliate 
        <br className="hidden lg:inline-block text-yellow-400" />Marketing Potential
      </p>
      <p className="mb-8 leading-relaxed">We unite all your campaigns under one platform, minimizing the complexity and amplifying your productivity. Our system automatically generates and tracks affiliate links, liberating you from tedious manual tasks.</p>
      <div className="flex items-center justify-center">
        <a  rel="noreferrer"
     href="https://publisher.yieldlink.net/register" 
     target="_blank" 
     className=" w-44 text-white bg-indigo-400 border-0 p-3 focus:outline-none text-center hover:bg-indigo-500 text-lg rounded-3xl">Join as Creator</a>
        <Link to="/contact">
        <button className="w-44 ml-4 text-black bg-orange-500 border-0 p-3  text-center focus:outline-none hover:bg-gray-200  text-lg rounded-3xl">Contact Us</button>		
         </Link>
      </div>
		<hr className="w-full h-1  my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
  
<div className="flex flex-wrap m-5 gap-x-24">
      
	  <div className="p-3 w-44">
        <div className="bg-transparent rounded-lg  text-center ">
          <h2 className="tracking-widest  title-font font-bold text-black mb-1  text-7xl">79M+</h2>
		  Views
        </div>
      </div>
	  
	   <div className="p-3 w-44">
        <div className="bg-transparent rounded-lg  text-center ">
          <h2 className="tracking-widest title-font font-bold text-black mb-1  text-7xl">12M+</h2>
		  Followers
        </div>
      </div>
	  
	   <div className="p-3 w-44">
        <div className="bg-transparent rounded-lg  text-center ">
          <h2 className="tracking-widest  title-font font-bold text-black mb-1  text-7xl">34M+</h2>
		  Likes
        </div>
      </div>
	   
     
</div>
		 
		 
    </div>
    <div className="md:w-1/2 w-5/6 h-4/5 bg-gray-100 border border-gray-100" style={{'backgroundImage':'url(img/box.png)'}}>
      <div className="min-h-screen hero-image bg-right-bottom bg-cover flex" style={{'backgroundImage':'url(slider.png)'}}>
	<img className="w-9/12 border border-slate-200 mt-10" alt="hero" src="./img/sml2.jpg" />
	  </div>
    </div>
  </div>
</section>

{/* <!--section end---> */}

<br />


{/* <!--section start---> */}

<Section3 />

 
{/* <!--section end---> */}


{/* <!---section start---end---> */}

<Section4 />


<Footer />
        </>
    )
}