import React from 'react'
import DarkHeader from '../Components/DarkHeader'
import Footer from '../Components/Footer'
import Section4 from '../Components/Sections/Section4'
import Section3 from '../Components/Sections/Section3'
function Privacy() {
  return (
    <div>
    <DarkHeader />
    
    <section className="text-gray-600 body-font mt-5 p-12">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
	  
      <h4 className="title-font sm:text-6xl text-2xl mb-4  text-gray-900 font-bold leading-tight">Privacy Policy
      </h4>
      <p className="mb-8 leading-relaxed text-sm">Privacy is important at Yieldlink.net (the “our” or “we”). We fully understand that as a Publisher, Advertiser or End User; there is a need to know how your personal information about your website and/or browsing behavior is being collected, how it is used and how it is shared; when you visit the Yieldlink.net website (the “Website”), engage in Yieldlink.net services (the “Services”) or use the Yieldlink.net technology (the “Software”).
      <br/>
      <br/>


A cookie is a small text file that is stored on the user's computer in order to track and keep a record of the previous visit to the website. The cookies are of two kinds session cookies and persistent cookies. Session cookies make the navigation of the website easy and expire when the browser is closed. Persistent cookies on the other hand remain on the hard disc for a long duration of time. The primary functions of Persistent cookies are to understand your preference and to work to accentuate your experience. Persistent cookies can be removed by the browser's "clear cookies" function.  <br/>

Yieldlink.net use third parties and analytics Technologies by which cookies are dropped on the website and collect non-personally identifiable information about the user in order to understand their use and access to the website. The website has no access to or control over these cookies and agrees to the privacy policy you agree to the cookies being dropped over the device by which Yieldlink.net has accessed to.
Yieldlink.net retains the right to edit alter and make changes in the privacy policy at any time so it is recommended to view it frequently. If material changes are made to the policies and the publisher is in merchants will be notified by the means of publisher Hub and merchant hub respectively.
<br/>
<br/>

What kind of information does Yieldlink.net curate? When a publisher signs up at Yieldlink.net, the following are the kinds of personally identifiable information that are collected by the website. Full name of the publisher of the publisher contact if the two names are different. email address telephone number website information Bank / Paypal details payment and VAT information house address how you came to know about Yieldlink.net. Apart from the aforementioned information, Yieldlink.net curates information as to when and how a publisher uses the publisher hub include other reports tools and interfaces that are provided by the website. Yieldlink.net collect the information to render the publisher with the update about the performance and billing and to ameliorate the services which are provided to the publishers by Yieldlink.net based on how they used in the past. The information of publishers is also used to introduce them to the merchants and advertisers for any further purchases or exchange of services. <br/>
<br/>

The information provided to Yieldlink.net can be edited by the publisher within the publisher hub. Furthermore, it is the responsibility of the publisher to log off the publisher hub once not in use to keep the password confidential and to protect the information against any illegitimate use and access. Publishers and their privacy policy It is the responsibility of the publisher to ensure that privacy policy, "about us" and other related and corresponding section to the website should contain the following salient features and at the publisher on the user's approval for each of these clauses: 1. A statement regarding the use of the service of the Yieldlink.net enabled site that as ''Our website accommodates some of the affiliate marketing Links i.e any sales made on the service after that we write about earns us Commission are articles in content however no influence by such advertiser affiliate affiliation. 2. The precise information about the cookies that are dropped on the user's computer by the means of the third parties analytic Technologies when a user accesses the website or clicks the affiliate link NASA link enabled sites and publisher toolbars. Furthermore, it is also the duty of the publisher to include in the privacy policy information regarding the disabling of Yieldlink.net Optimisation cookies.
<br/>

Share with third parties
It is made a player that Yieldlink.netYieldlink.net might share a certain part of the publisher's information with the partners and advertisers to accentuate the mutual relation between the advertiser and the merchants within turn lead to the increment of revenue share for the Publishers. The date that can be disclosed by Yieldlink.net kin conditions 1. when Yieldlink.net observes a potential commercial relationship between the publisher and merchant. 2. When a sale or purchase of a business or an asset is made the information is to be close to the buyer-seller to the business or asset. 3. When we hold the responsibility to disclose your personal information and sharing to satisfy the court or jurisdiction of the competent Government and other legal obligations of for the application or application of the Yieldlink.net service requirements for the protection of the right, property, or security Yieldlink.net or our Client or others.
<br/>
<br/>

What kind of information does Yieldlink.net curate? Following is the type of Personally Identifiable Information that is collected by the website when a merchant signs up to avail the services. Full name of the Merchant and, if different, the name of the merchant contact email address telephone number website information Bank / Paypal details payment and VAT information house address Apart from the aforementioned information, Yieldlink.net curates information as to when and how a Merchant uses the Merchant Hub include other reports, tools and interfaces that are provided by the website. How is the collected information used? Yieldlink.net use the information to enforce its obligations under the convention to evaluate the property of Merchant for the services and to contact the Merchant to offer them help regarding the registration adoption and the development of the services. The collected information is also used to bill the Merchant their percentage of the commission that is billed for the services and for any interaction regarding any Yieldlink.net related matters. Yieldlink.net collect the information to render the Merchant with the update about the performance and billing and to ameliorate the services which are provided to the Merchant by Yieldlink.net based on how they used in the past. The information is also used to introduce them with the Publishers for any further purchases or exchange of services. <br/>

The information provided to Yieldlink.net can be edited by the Merchant within the Merchant Hub. Furthermore, it is the responsibility of the Merchant to log off the Merchant Hub once not in use to keep the password confidential and to protect the information against any illegitimate use and access. Publishers and their privacy policy It is the responsibility of the publishers to ensure that the privacy policy "about us" and other related and corresponded sections of the website should contain the following silent features and that the merchants on the user's approval on each of the following information the precise information about the cookies that are dropped on user's computer by the means of third parties analytics Technology when a user accesses the website of clicks the affiliate link nestling enable sites and publisher toolbars. Furthermore, it is also the duty of the merchant to include in the privacy policy information regarding disabling the use of Yieldlink.net Optimisation cookies. <br/>
yieldlink stores the following registration data for contract fulfilment and customer support: Yieldlink.net keeps its priority to safeguard your personally identifiable information and would never disclose it without your consent however these are conditions in which information would be revealed to the third parties such as 1. when Yieldlink.net observes a potential commercial relationship between the publisher and merchant. 2. When a sale or purchase of a business or an asset is made the information is to be close to the buyer-seller to the business or asset. 3. When we hold the responsibility to disclose your personal information and sharing to satisfy the court or jurisdiction of the competent Government and other legal obligations of for the application or application of the Yieldlink.net service requirements for the protection of the right, property, or security Yieldlink.net or our Client or others. What kind of information does Yieldlink.net curate? This section deals with how Yieldlink.net functions to the Yieldlink.net enabled sites and publisher toolbars and how it collects the user's information.
</p>
   
    </div>
    <div className="lg:max-w-2xl lg:w-full md:w-1/2 w-5/6">
    <div className="lg:max-w-2xl w-full">
      <img className="object-cover object-center rounded-3xl lg:-ml-12" alt="hero" src="./img/girl.jpg" />
	  <div className="w-3/5  bg-white lg:-mt-72 lg:-ml-24" >
	      <img className="object-cover  h-96 rounded-2xl object-center border border-slate-200 " alt="hero" src="./img/small.jpg" />
	  </div>
    </div>
	  
	 <div className='mt-3'> 
	   <div className="w-12/12 ">
	  <img className="w-12/12" alt="hero" src="Main-slider_img.png" />
	  </div>
	  
     </div>
    </div>
	
  </div>
</section>

<Section3 />
<Section4 />
    <Footer />
</div>
  )
}

export default Privacy