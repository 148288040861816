import React from 'react'

function Section3() {
  return (
    <div>
        <div className="grid grid-rows w-11/12 m-auto grid-flow-col gap-2 pt-5 pb-5">

<div className="row-span-3 col-span-2 mt-5">
    
    <div className="flex flex-wrap p-4 w-full sm:12/12">
      <div className="h-full  bg-gray-100 border-2  border-gray-200 border-opacity-60 rounded-lg overflow-hidden pb-5">
        <img className="w-full" src="./img/e3.png" alt="sep" />
        <div className="p-6">
        <h1 className="title-font sm:text-2xl text-2xl mb-4 font-bold text-gray-900">Innovative Tracking Technology</h1>
         <p className=" leading-relaxed mt-4">Our platform offers innovative tracking solutions. You can track all aspects of your campaigns precisely. With just one link, you can generate a link and our platform ensures the accuracy of your commission</p>
        </div>
      </div>
    </div>
    
</div>
{/* <!--first end--> */}
<div className="col-span-2 bg-gray-100  p-3 m-2 border-2 border-gray-200 border-opacity-60 rounded-lg">

 
<div className="container mx-auto flex md:flex-row flex-col items-center">
  <div className=" w-3/5 mb-10 md:mb-0">
    <img className="object-cover object-center rounded" alt="hero" src="./img/e2.png" />
  </div>
  <div className="lg:flex-grow md:w-1/2 pl-5 flex flex-col md:items-start md:text-left items-center text-center">
    <h1 className="title-font sm:text-2xl text-2xl mb-4 font-bold text-gray-900">Top-tier Merchant Collaborative
    </h1>
    <p className="mb-8 leading-relaxed">By joining our Top-tier Merchant Collaborative, you gain instant access to an elite selection of over 15,000 merchants.</p>
  </div>
</div>
 

{/* </div><!--col-2 end--> */}

<div className="row-span-2 col-span-2 p-3 m-2 border-2 bg-gray-100 border-gray-200 border-opacity-60 rounded-lg">
    
   <div className="container mx-auto flex md:flex-row flex-col items-center ">
  <div className=" w-2/5 mb-10 md:mb-0">
    <img className="object-cover object-center rounded" alt="hero" src="./img/e4.png" />
  </div>
  <div className="lg:flex-grow md:w-1/2 pl-5 flex flex-col md:items-start md:text-left items-center text-center">
    <h1 className="title-font sm:text-2xl text-2xl mb-4 font-bold text-gray-900">Innovative technology at your fingertips

    </h1>
    <p className="mb-8 leading-relaxed">Our advanced and innovative tools are designed to make the creating and earning process streamlined. Our iOS and Android apps enable you to get it done on-the-go.
</p>
  </div>
</div> 
    
    
</div>

</div>
{/* <!--top three block end--> */}

</div>
<div className="flex flex-wrap w-11/12  m-auto pb-5">

    <div className="p-4 md:w-1/3 ">
      <div className="h-full border-2  bg-gray-100 border-gray-200 border-opacity-60 rounded-lg overflow-hidden pt-4">
        <img className="lg:h-40 md:h-16 object-cover object-center align-center text-center m-auto" src="./img/e5.png " alt="blog" />
        <div className="p-6">
       
          <h1 className="title-font text-lg text-center font-bold text-gray-900 mb-3">Innovative Tracking Technology</h1>
          <p className="leading-relaxed mb-3 text-center">Discover merchants and campaigns that resonate with your audience.</p>
        
        </div>
      </div>
    </div>
    
     <div className="p-4 md:w-1/3 ">
      <div className="h-full border-2  bg-gray-100 border-gray-200 border-opacity-60 rounded-lg overflow-hidden pt-4">
        <img className="lg:h-40 md:h-16 object-cover object-center align-center text-center m-auto" src="./img/e6.png " alt="blog" />
        <div className="p-6">
       
          <h1 className="title-font text-lg text-center font-bold text-gray-900 mb-3">Create Compelling Content</h1>
          <p className="leading-relaxed mb-3 text-center">Develop high-quality content around the products you promote.</p>
        
        </div>
      </div>
    </div>
    
     <div className="p-4 md:w-1/3 ">
      <div className="h-full border-4  border-opacity-60 rounded-lg overflow-hidden pt-4 bg-indigo-500 border-indigo-500">
        <img className="lg:h-40 md:h-16 object-cover object-center align-center text-center m-auto" src="./img/e7.png " alt="blog" />
        <div className="p-6">
       
          <h1 className="title-font text-lg text-center font-bold mb-3 text-white" >Create and Share the links</h1>
          <p className="leading-relaxed mb-3 text-center text-white">Create affiliate links, integrate links into your content and share on your channels.</p>
        
        </div>
      </div>
    </div>
    
    
 
  </div>

    </div>
  )
}

export default Section3