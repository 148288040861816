import { Link } from "react-router-dom"
export default function Footer() {
    return (
        <>
<footer className="bg-gray-100 body-font mt-5 bottom-0 place-self-end">
  <div className="container px-5 py-24 mx-auto ">
    <div className="flex flex-wrap md:text-left text-center order-first">
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="w-7/12 title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
		<img src="./yieldlink-default_1.png" className="w-48" alt="yieldlink-logo" />
		</h2>
        <nav className="list-none mb-10">
          <p className="">YieldLink.net is a full service affiliate management platform for high-quality publishers to instantly find, connect and earn from the world's.
		  <br />
		 <b> Phone:</b> +19704783082
		   <br />
       <b> Email: </b>Support@Yieldlink.net
		  </p>
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-bold text-yellow-400 tracking-widest text-sm mb-3">CATEGORIES</h2>
        <nav className="list-none mb-10">
          <li>
            <Link to="/cookies" className="text-black hover:text-black">&#9737; INFORMATIONAL COOKIES</Link>
          </li>
          <li>
            <Link to="/privacy" className="text-black hover:text-black">&#9737; PRIVACY POLICY</Link>
          </li>
          <li>
            <Link to="/terms" className="text-black hover:text-black">&#9737; TERMS & CONDITION</Link>
          </li> 
          
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h1 className="title-font font-bold text-yellow-400 tracking-widest text-sm mb-3">CATEGORIES</h1>
        <nav className="list-none mb-10">
          <li>
            <Link to="/about" className="text-black hover:text-black">&#9737; ABOUT</Link>
          </li>
        <li>
            <Link to="/imprint" className="text-black hover:text-black">&#9737; IMPRINT</Link>
          </li>
          <li>
            <Link to="/contact" className="text-black hover:text-black">&#9737; CONTACT</Link>
          </li> 
          
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">SUBSCRIBE</h2>
      <div className="flex justify-between mb-3">
            <input className="mr-3 shadow-inner text-white rounded text-sm bg-gray-200 p-3 border-b border-gray-200" placeholder="Enter your email" type="text" />
            <button className="border-t border-yellow-400 shadow-2xl bg-yellow-400 p-3 text-white font-bold text-sm rounded px-3 py-2">Subscribe</button>
        </div>
      </div>
    </div>
  </div>
  <div className="bg-gray-900">
    <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
      <Link to="/" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
  
      <h2 className="w-7/12 title-font font-medium place-self-center text-gray-900 tracking-widest text-sm mb-3">
      <img src="./yieldlink-white_1.png" className="w-48" alt="yieldlink-logo" />
        </h2>
      </Link>   
      <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4 text-center"> Copyright © Yieldlink.Net. All Rights Reserved.
        <Link to="/" className="text-gray-600 ml-1">

        </Link>
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <Link to="/" className="text-gray-500">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </Link>
        <Link to="/" className="ml-3 text-gray-500">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </Link>
        <Link to="/" className="ml-3 text-gray-500">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </Link>
        <Link to="/" className="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </Link>
      </span>
    </div>
  </div>
</footer>
        </>
    )
}