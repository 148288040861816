import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Section4 from '../Components/Sections/Section3'
function Publisher() {
  return (
    <div>
        <Header />
                {/* hero-section */}
                <div className="min-h-screen hero-image bg-right-bottom bg-cover flex" style={{'backgroundImage':'url("./slider.png")'}}>
<section className="text-gray-600 body-font">

  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-26 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="title-font sm:text-5xl  mb-4  text-gray-900 font-bold sm:mt-5">Publisher
      </h1>
      <p className="mb-8 leading-relaxed text-black">At Yieldlink.net, we define a publisher as a digital influencer or a digital content provider with a clear purpose. The term “publisher” covers a large array of digital blogs, websites, forums, platforms, mobile apps, web apps, content networks, ecommerce sites, media websites or other sites with published content that might be looking for an exciting new, more optimized or customized way to earn money from their quality content. If you are unsure if you fit our definition of a potential publisher, then just ask us through the contact form.</p>
      <div className="flex justify-center">
        <a 
         rel="noreferrer"
         href="https://publisher.yieldlink.net/register" 
         target="_blank" 
        className=" grid-cols-2 ml-3 bg-white hover:bg-white text-center text-black mx-auto leading-relaxed text-base font-bold py-2 px-4 rounded-full align-center">
 Join as Creator
</a>
      </div>
    </div>
    <div className="lg:max-w-lg lg:w-full">
      <img className="rounded-3xl" alt="hero" src="./publisher-1.jpg" />
    </div>
  </div>
</section>
</div>
<Section4 />
        <Footer />
    </div>
  )
}

export default Publisher