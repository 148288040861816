import React from 'react'
import DarkHeader from '../Components/DarkHeader'
import Footer from '../Components/Footer'
import Section3 from '../Components/Sections/Section3'
import Section4 from '../Components/Sections/Section4'
function About() {
  return (
    <div>
        <DarkHeader />
        <section className="text-gray-600 body-font mt-5 p-12">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
	  
      <h4 className="title-font sm:text-6xl text-2xl mb-4  text-gray-900 font-bold leading-tight">About Us

      </h4>
      <p className="mb-8 leading-relaxed text-normal">We offers innovative tools and exclusive campaigns for bloggers and publishers seeking affiliate marketing programs to monetize blog content. YieldLink.net is a full service affiliate management platform for high-quality publishers to instantly find, connect and earn from the world's.
The Most coveted brands Is leading Affiliate Network where you will find both the amazing Advertisers and high ranking content creating Affiliates.
Manage your affiliate marketing program, influencer marketing program, referral program, brand ambassaor program, and more.
Highest expectations of quality, because we know that quality will always win over quantity. We also believe, transparency is the key for substantial & honest growth Should never be sacrificed for short term profits.

 </p>
 
 
    </div>
    <div className="lg:max-w-2xl lg:w-full md:w-1/2 w-5/6">
    <div className="lg:max-w-2xl w-full">
      <img className="object-cover object-center rounded-3xl lg:-ml-12" alt="hero" src="./img/girl.jpg" />
	  <div className="w-3/5  bg-white lg:-mt-72 lg:-ml-24" >
	      <img className="object-cover  h-96 rounded-2xl object-center border border-slate-200 " alt="hero" src="./img/small.jpg" />
	  </div>
    </div>

    </div>
	
  </div>
</section>
        <Section3/>

        <Section4 />
        <Footer />
    </div>
  )
}

export default About