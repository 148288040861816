import { NavLink } from "react-router-dom"
export default function Header() {
    return (
        <>
<nav className=" dark:bg-gray-900 w-full z-20 top-0 left-0 dark:border-gray-600 absolute mb-10">
  <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
  <NavLink to="/" className="flex items-center">
      <img src="./yieldlink-default_1.png" className="w-32 mr-3" alt="Yieldlink.net" />
      
  </NavLink>
  <div className="flex md:order-2 gap-x-4">
      <a 
      rel="noreferrer"
      href="https://publisher.yieldlink.net/register  " 
      target="_blank" 
      className="grid grid-cols-1 bg-yellow-400 mt-2 hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded-full">
 Register
</a>
<a 
      rel="noreferrer"
      href="https://publisher.yieldlink.net/login" 
      target="_blank" 
      className="grid grid-cols-1 bg-blue-300 mt-2 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-full">
 Login
</a>
      <button data-collapse-toggle="navbar-sticky" type="button" className=" inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
  </div>
  <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
    <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-transparent md:flex-row md:space-x-8 md:mt-0 md:border-0 ">
      <li>
        <NavLink to="/" className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page">Home</NavLink>
      </li>
      <li>
        <NavLink to="/influencer" className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Influencer</NavLink>
      </li>
      <li>
        <NavLink to="/blogger" className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Blogger</NavLink>
      </li>
      <li>
        <NavLink to="/publisher" className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Publisher</NavLink>
      </li>
    </ul>
  </div>
  </div>
</nav>
        </>
    )
}